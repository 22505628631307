<template>
  <v-card class="card text-center">
    <h3 class="card-text font-family-raleway-medium">
      {{ $t("create_campaign_page.input_basic_info_step_title") }}
    </h3>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="text-left mt-10">
        <v-text-field
          class="input-border font-family-raleway-medium"
          :hide-details="statusCode === 400"
          v-debounce:200ms="myDebounceFn"
          v-model.trim="companyName"
          :class="'border-red'"
          :label="$t('create_campaign_page.campaign_name_input')"
          :rules="nameRules"
          :error="statusCode === 400"
          height="60"
          outlined
          required
        >
          <template v-slot:append>
            <div class="d-flex align-center">
              <v-btn
                v-if="companyName"
                class="py-2 mr-2"
                height="30"
                icon
                @click="clearName"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <span v-if="isCheckingName">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="18"
                ></v-progress-circular>
              </span>
              <div v-else>
                <span v-if="statusCode === 200">
                  <img :src="check" class="mt-1" height="20" width="20" />
                </span>
                <span v-else-if="statusCode === 400">
                  <v-icon color="red"> mdi-close </v-icon>
                </span>
              </div>
            </div>
          </template>
        </v-text-field>
        <span
          class="color-red font-family-raleway-medium ml-3"
          style="font-size: 13px"
          v-if="statusCode === 400"
          >{{ $t("create_campaign_page.name_exist") }}</span>
      </div>
      <div class="text-left">
        <v-text-field
          class="input-border font-family-raleway-medium"
          :rules="urlRules"
          :label="$t('create_campaign_page.url_input')"
          v-model="url"
          height="60"
          required
          outlined
        >
          <template v-slot:append>
            <v-btn
              v-if="url"
              class="py-2 mr-2"
              height="30"
              icon
              @click="clearUrl"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <span>
              <img
                v-if="url"
                :src="check"
                alt=""
                class="mt-1"
                height="20"
                width="20"
              />
            </span>
          </template>
        </v-text-field>
      </div>
      <div class="text-left">
        <v-text-field
          class="input-border pb-0 mb-0 font-family-raleway-medium"
          @keyup.enter="handleAdd"
          :rules="keywordRules"
          v-model="keyword"
          :label="$t('create_campaign_page.add_keyword_input')"
          height="60"
          outlined
          required
        >
          <template v-slot:append>
            <v-btn class="add-btn" @click="handleAdd"> {{ $t("create_campaign_page.add_button") }}</v-btn>
          </template>
        </v-text-field>
      </div>
      <v-chip
        v-for="(item, index) of chips"
        :key="index"
        class="chip my-1 ml-2 font-family-raleway-medium"
        outlined
      >
        {{ item.text }}
        <v-icon class="ml-2" @click="handleRemove(item)"> mdi-close</v-icon>
      </v-chip>
      <v-row class="align-center pb-5">
        <v-col class="mt-5 font-family-raleway-medium" cols="5">
          <v-btn
            class="back-btn text-decoration-underline"
            @click="handleClickBack"
          >
            {{ $t("back_button") }}
          </v-btn>
        </v-col>
        <v-col class="mt-5 font-family-raleway-medium text-right" cols="7">
          <v-btn
            class="button font-weight-bold next-btn font-family-raleway-medium"
            @click="handleClick"
            height="60"
          >
            {{ $t("create_campaign_page.next_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import check from "@/assets/img/check.svg";
import { campaignService } from "@/services/campaign";

export default {
  name: "BasicInfo",
  data() {
    return {
      companyName: "",
      url: "",
      keyword: "",
      statusCode: 0,
      chips: [],
      check,
      valid: true,
      isValid: false,
      isCheckingName: false,
      isKeywordsvalid: false,
      nameRules: [(v) => !!v || this.$t("required_text")],
      keywordRules: [
        () => !!this.isKeywordsvalid || this.$t("create_campaign_page.keywords_validation_text"),
      ],
      urlRules: [
        (v) => !!v || this.$t("required_text"),
      ],
    };
  },
  created() {
    const cam = localStorage.getItem("campaign");
    const parseToObj = JSON.parse(cam);
    if (parseToObj?.keywords?.length) {
      this.chips = parseToObj.keywords.map((item) => {
        return { text: item, isActive: true };
      });
    }
    if (parseToObj?.name) {
      this.companyName = parseToObj.name;
    }
    if (parseToObj?.url) {
      this.url = parseToObj.url;
    }
    if (this.chips.length > 0) {
      this.isKeywordsvalid = true;
    }
  },
  methods: {
    async myDebounceFn() {
      const customerId = localStorage.getItem("customerId");
      if (this.companyName && customerId) {
        this.isCheckingName = true;
        try {
          await campaignService.checkCampaignName(this.companyName, customerId);
          this.statusCode = 200;
          this.isValid = true;
        } catch (e) {
          this.statusCode = e.response?.status;
        }
        this.isCheckingName = false;
      } else {
        this.statusCode = 0;
      }
    },
    handleRemove(item) {
      this.chips = this.chips.filter((i) => i.text !== item.text);
      if (this.chips.length === 0) {
        this.isKeywordsvalid = false;
      }
    },
    handleAdd() {
      if (this.keyword) {
        this.chips.push({
          text: this.keyword,
          isActive: true,
        });
        this.isKeywordsvalid = true;
      }
      this.keyword = "";
    },
    clearName() {
      this.companyName = "";
    },
    clearUrl() {
      this.url = "";
    },
    async handleClick() {
      await this.$refs.form.validate();
      if (this.chips.length > 0) {
        this.isKeywordsvalid = true;
      }
      if (this.valid) {
        const cam = JSON.parse(localStorage.getItem("campaign"));
        const keywords = this.chips.map((item) => {
          if (item.isActive) {
            return item.text;
          }
        });
        localStorage.setItem(
          "campaign",
          JSON.stringify({
            ...cam,
            keywords: keywords,
            companyName: "",
            name: this.companyName,
            url: this.url,
          })
        );
        localStorage.setItem("currentUrl", "suggest-keyword");
        await this.$router.push("suggest-keyword");
      }
    },
    handleClickBack() {
      this.$router.push("advertising-goals");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/style/style.scss";

.add-btn {
  background-color: transparent !important;
  box-shadow: none;
  margin-bottom: 30px !important;
  color: #0057ff;
  font-weight: bold;
  height: 30px !important;
}

.chip {
  height: 48px !important;
}
</style>
